jQuery(function ($) {
  var isPc = $(window).width() > 980
  var isTablet = $(window).width() > 800
  var isMobileBind = false
  $(document).ready(function(){
    $('#header').load('/_components/header.html', function () {
      menuHandler()
    })
    $('#footer').load('/_components/footer.html')
  })
  $(document).on('click', '.subMenu h2' , function () {
    $(this).siblings().toggleClass('open')
  })
  $(document).on('click', '#btnLang' , function () {
    $(this).siblings('.languageList').toggle()
  })
  $(document).on('click', '.tabBox button' , function () {
    // console.log(target)
    var target = $(this).data('target')
    $(this).addClass('current').siblings().removeClass('current')
    $(target).show().siblings().hide()
  })
  $( window ).resize(function () {
    var isPcNew = $(window).width() > 980
    var isTabletNew = $(window).width() > 800
    if (isPc !== isPcNew) {
      isPc = isPcNew
      menuHandler()
    }
    if (isTablet !== isTabletNew) {
      isTablet = isTabletNew
      $('.subMenu').find('.open').removeClass('open')
    }
    // console.log(isPc)
  })
  var btn
  $.fn.modalHandler = function (bool, target) {
    // console.log(bool, target)
    if (bool) {
      btn = $(this)
      $(target).show()
      $(target).find('.btnClose').focus()
      var winH = $(window).height()
      var domH = $(target).find('.modal').height()
      if (winH > domH) {
        $(target).removeClass('fit')
      } else {
        $(target).addClass('fit')
      }
    } else {
      btn.focus()
      btn = null
      $(target).hide()
      $(target).removeClass('fit')
    }
  }
  function menuHandler() {
    if (isPc) {
      $('.topNav a').removeClass('on')
      $('.mWrap').removeClass('active')
      $('.menuWrap').hide()
      $('.topNav a').hover(function () {
        var target = $(this).data('hover')
        $(target).addClass('on').siblings().removeClass('on')
      })
      $('.topNav').hover(function () {
        $('.menuWrap').stop().slideToggle(300)
      })
    } else {
      // console.log('isM')
      $('.topNav').unbind('mouseenter mouseleave')
      $('.menuWrap').css({height: 'auto'})
      $(document).on('click', '.btnMenu' , function () {
        $('.menuWrap').fadeIn(200)
        $('.mWrap').addClass('active')
      })
      $(document).on('click', '#menuClose' , function () {
        $('.menuWrap').fadeOut(200)
        $('.mWrap').removeClass('active')
      })
      $('.titleWrap').click(function () {
        $(this).siblings('ul').slideToggle()
        $(this).parent().siblings().find('ul').slideUp()
      })
    }
  }
})